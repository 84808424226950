<script setup>
import { useMetaStore } from '~/stores/meta.store'
import DesktopHomeBanner from './partials/banner.vue';
import DesktopHomeBecomeAgent from './partials/become-agent.vue';
import DesktopTopTenListings from './partials/top-ten-listings.vue';
import DesktopDiscountListings from './partials/discount-listings.vue';
import DesktopAgents from './partials/agents.vue';
import DesktopLatestListings from './partials/latest-listings.vue';
import DesktopHero from './partials/hero.vue';

        const metaStore = useMetaStore();
        const config = useRuntimeConfig();

        const meta = ref({});
        metaStore.getMetaTags()

        meta.value = metaStore.metaGetter();

        metaStore.setMeta(meta.value);

        const appId = useRuntimeConfig().public.appId;

        useSchemaOrg({
            "@context": "https://schema.org",
            "@type": "RealEstateAgent",
            "name": "Vartur",
            "image": `${config.public.websiteUrl}/images/${appId}/logo.webp"`,
            "@id": `${config.public.websiteUrl}/property/for-sale/turkey"`,
            "url": `${config.public.websiteUrl}/property/for-sale/turkey"`,
            "telephone": "+90 (212) 803-0105",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Vadistanbul 2A Blok Ofis No:90 Sarıyer/İstanbul",
                "addressLocality": "İstanbul",
                "postalCode": "34453 ",
                "addressCountry": "TR",
                "addressRegion": "Sarıyer / İstanbul"
            },
            "sameAs": [
                "https://www.facebook.com/varturcom",
                "https://twitter.com/varturcom",
                "https://www.linkedin.com/company/vartur/",
                "https://www.youtube.com/channel/UChY-2Twc3ov1R9cRVPIizjw",
                `${config.public.websiteUrl}`,
            ],
        });

        useSchemaOrg({
            "@context": "https://schema.org",
            "@type": "RealEstateAgent",
            "name": "Vartur",
            "image": `${config.public.websiteUrl}/images/${appId}/logo.webp"`,
            "@id": `${config.public.websiteUrl}/property/for-sale/uae"`,
            "url": `${config.public.websiteUrl}/property/for-sale/uae"`,
            "telephone": "+90 (212) 803-0105",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Sheikh Zayed Road, Al Attar Business center Office 216, Al Barsha First, Dubai",
                "addressLocality": "Dubai",
                "postalCode": "00000",
                "addressCountry": "AE",
                "addressRegion": "Al Barsha / Dubai"
            },
            "sameAs": [
                "https://www.facebook.com/varturcom",
                "https://twitter.com/varturcom",
                "https://www.linkedin.com/company/vartur/",
                "https://www.youtube.com/channel/UChY-2Twc3ov1R9cRVPIizjw",
                `${config.public.websiteUrl}`,
            ],
        });

        useSchemaOrg({
            "@context": "https://schema.org",
            "@type": "Corporation",
            "name": "Vartur",
            "alternateName": "vartur.com",
            "url": `${config.public.websiteUrl}`,
            "logo": `${config.public.websiteUrl}images/${appId}/logo.webp`,
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+90 (212) 803-0105",
                "contactType": "customer service",
                "areaServed": ["TR", "AE", "RU", "GB", "US"],
                "availableLanguage": ["Turkish", "en", "Russian"]
            },
            "sameAs": [
                "https://www.facebook.com/varturcom",
                "https://twitter.com/varturcom",
                "https://www.youtube.com/channel/UChY-2Twc3ov1R9cRVPIizjw",
                "https://www.linkedin.com/company/vartur/"
            ]
        });

        useSchemaOrg({
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "Vartur",
            "url": `${config.public.websiteUrl}`,
            "potentialAction": {
                "@type": "SearchAction",
                "target": "{search_term_string}",
                "query-input": "required name=search_term_string"
            }
        });
</script>
<template>
    <LayoutAppHead></LayoutAppHead>
    <NuxtLayout name="desktop-default">
        <!-- Start hero -->
        <DesktopHero></DesktopHero>
        <!-- End hero -->

        <!-- Start Banner -->
        <DesktopHomeBanner></DesktopHomeBanner>
        <!-- End Banner -->

        <!-- Start Newly Added Real Estates -->
        <DesktopLatestListings></DesktopLatestListings>
        <!-- End Newly Added Real Estates -->

        <!-- Start Top 10 Real Estates -->
        <DesktopTopTenListings></DesktopTopTenListings>
        <!-- End Top 10 Real Estates -->

        <!-- Start Discounted Real Estates -->
        <DesktopDiscountListings></DesktopDiscountListings>
        <!-- End Discounted Real Estates -->

        <!-- Start Type User or Agent -->
        <DesktopHomeBecomeAgent></DesktopHomeBecomeAgent>
        <!-- End Type User or Agent -->

        <!-- Start Agent Team -->
        <DesktopAgents></DesktopAgents>
        <!-- End Agent Team -->

    </NuxtLayout>
</template>